import React, { Component } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Scene } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import bigfoot from './bigfoot.glb'

// import moonmask from './models/moonmask.glb'
// import moontexture from './models/moon.png'
import { red } from '@material-ui/core/colors';
class Sasquatch extends Component{
  componentDidMount(){
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
// Texture loader
this.textureLoader = new THREE.TextureLoader()
// this.bakedTexture = this.textureLoader.load(moontexture)

this.bakedMaterial = new THREE.MeshBasicMaterial(red)
// this.bakedTexture.flipY = false
// Draco loader
this.dracoLoader = new DRACOLoader()
this.dracoLoader.setDecoderPath('draco/')

// GLTF loader
this.gltfLoader = new GLTFLoader()
this.gltfLoader.setDRACOLoader(this.dracoLoader)

//ADD SCENE
this.scene = new THREE.Scene()
this.gltfLoader.load(
    bigfoot,
    (gltf) =>
    {
      console.log(gltf.scene)
        gltf.scene.traverse((child) =>
        {
            child.material = this.bakedMaterial
        })
        // gltf.scene.rotation.x += .01
        gltf.scene.position.y = -5
        

        this.scene.add(gltf.scene)
        
    }
    // ,this.gltf.rotation.y += 0.01
)
    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(
      75,
      width / height,
      0.1,
      1000
    )
    this.camera.position.z = 100
    
    this.camera.position.y = -1
    

    // this.controls = new OrbitControls(this.camera, this.mount)
    // this.controls.enableDamping = true
    //ADD RENDERER
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setClearColor('#282c34')
    this.renderer.setSize(width, height)
    this.mount.appendChild(this.renderer.domElement)
    //ADD CUBE
    // const geometry = new THREE.BoxGeometry(1, 1, 1)
    // const material = new THREE.MeshBasicMaterial({ color: '#433F81'     })
    // this.cube = new THREE.Mesh(geometry, material)
    // this.scene.add(this.cube)
this.start()
  }
componentWillUnmount(){
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }
start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }
stop = () => {
    cancelAnimationFrame(this.frameId)
  }
  
animate = () => {
  //  this.cube.rotation.x += 0.01
  //  this.cube.rotation.y += 0.01
   this.scene.rotation.y += -.001
   this.renderScene()
   this.frameId = window.requestAnimationFrame(this.animate)
 }
renderScene = () => {
  this.renderer.render(this.scene, this.camera)
}
render(){
    return(
      <div
        style={{ width: '100%', height: '15em', display: 'flex', flexDirection:'row', justifyContent:'center', marginRight:'0', position: 'flex' }}
        ref={(mount) => { this.mount = mount }}
      />
    )
  }
}
export default Sasquatch