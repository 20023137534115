import React from 'react'

const Footer = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p>Paranormal Investigations in the Tampa Bay Area ©2020 Expeditions Mopez</p>
        </div>
    )
}

export default Footer