import React from 'react'

const Contact = () => {
    return (
        <div className="home">
            <h1>Contact Us</h1>
            <h3 className="description">Had a paranormal experience recently? Let us know how we can help you understand this journey into the unknown.</h3>
            <div className="profile">
            <h3>Jose Lopez</h3>
            <p>Paranormal Investigator</p>
            </div>
            <div className="profile">
            <h3>Mykayla Mawicke</h3>
            <p>Paranormal Investigator</p>
            <h3><a href="mailto:joselopez@luckyfrog.io">joselopez@luckyfrog.io</a></h3>
            </div>

        </div>
    )
}

export default Contact
