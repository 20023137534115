import React from 'react'
import exmo from './exmo.svg';
import './App.css';


const Home = () => {
    return (
        <div className="home">
        
        <h2>Expeditions Mopez</h2>
        <img src={exmo} className="App-logo" alt="logo" />
        <p>
          Searching through the Supernatural
        </p>
      
        </div>
    )
}

export default Home
