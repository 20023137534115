import React from 'react'
import { Link, Router } from 'react-router-dom'
import exmo from './exmo.svg'

const Navbar = () => {
    return (
        <div className="navi">
            <ul>
                {/* <li><img src={exmo} className="App-logo" alt="logo" /></li> */}
                <li><Link  to="/">Home</Link></li>
                <li><Link  to="/services">Investigate</Link></li>
                <li><Link  to="/about">About</Link></li>
                <li><Link  to="/contact">Contact</Link></li>
            </ul>
        </div>
    )
}

export default Navbar
