import React from 'react'
import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from './Navbar';
import Section from './Section';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Scene } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import ThreeScene from './ThreeJs';
import Ufo from './UFO';
import Sasquatch from './Sasquatch';
import Ghost from './Ghost';
import ufo from './img/ufo.png';

// const canvas = document.querySelector('canvas.webgl')


// const bakedMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 })



// // Scene
// const scene = new THREE.Scene()

// /**
//  * Loaders
//  */
// // Texture loader
// const textureLoader = new THREE.TextureLoader()

// // Draco loader
// const dracoLoader = new DRACOLoader()
// dracoLoader.setDecoderPath('draco/')

// // GLTF loader
// const gltfLoader = new GLTFLoader()
// gltfLoader.setDRACOLoader(dracoLoader)

// gltfLoader.load(
//     ufo,
//     (gltf) =>
//     {
//       console.log(gltf.scene)
//         gltf.scene.traverse((child) =>
//         {
//             child.material = bakedMaterial
//         })
//         scene.add(gltf.scene)
//     }
// )

// /**
//  * Object
//  */
// const cube = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1),
//     new THREE.MeshBasicMaterial()
// )

// scene.add(cube)

// /**
//  * Sizes
//  */
// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight
// }

// window.addEventListener('resize', () =>
// {
//     // Update sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()

//     // Update renderer
//     renderer.setSize(sizes.width, sizes.height)
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// })

// /**
//  * Camera
//  */
// // Base camera
// const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
// camera.position.x = 30
// camera.position.y = 2
// camera.position.z = 4
// scene.add(camera)

// // Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//     canvas: canvas,
//     antialias: true
// })
// renderer.setSize(sizes.width, sizes.height)
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// /**
//  * Animate
//  */
// const clock = new THREE.Clock()

// const tick = () =>
// {
//     const elapsedTime = clock.getElapsedTime()

//     // Update controls
//     controls.update()


//     // Render
//     renderer.render(scene, camera)

//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }

// tick()

// function animate() {
// 	requestAnimationFrame( animate );
// 	renderer.render( scene, camera );
// }
// animate();

const Services = () => {
    return (
         <div className="App">
        <Navbar />
        <div id="section1"
        className="section">
        <Section
          title="Hauntings"
          subtitle="Understanding apparitions"
          dark={true}/>
          <Ghost className='nav-logo' />
          <p className="description">There are many common reasons behind those eerie feelings. It could be the result of low frequencies that can cause mental distress, and even hallucinations. It could also be a case of confirmation bias, but we begin our investigation by ruling out the obvious before delving deeper.</p>
          <p className="description">These are not matters to be taken lightly as there are things that we may not even be aware of that will have lasting effects on a person. Whether you proceed with us or others, be cautious and always remember to thank your guest for there time, and to bid them goodbye.</p>
        </div>
        <div id="section2"
        className="section">
        <Section
          title="Cryptids"
          subtitle="Discover the legends"
          dark={false}
          glitch={ufo}
          />
          <Sasquatch className='nav-logo' />
          <p className="description">Walking through the woods, have you ever felt like you're being watched? Maybe a slight chill goes down your spine as you realize its getting darker faster than you thought.</p>
          <p className="description">You start to smell something out of place and hear faint knocking sounds. You chock this up to woodpeckers, or maybe someone has relieved themselves nearby, and keep heading back. The feeling of something being out there starts to become a burden; one thats getting harder and harder to rationalize. You start to see where your car is, but, before you make it out of the treeline, a large rock falls, just off the trail to you. You start to hear twigs snapping behind you, but you just know you have to get to your car, and then you can let your curiosity free. You open your door, and adrenaline flowing, you shut it with much more force than you needed to behind you. You look out the window, and just before it leaves your sight, retreating to shadows, you see It.</p>
          <p className="description">Some have had this very experience, and whether you would believe in it or not, unexplainable things are out there. </p>
        </div>
        <div id="section3"
        className="section">
          <Section
          title="Extraterrestrial"
          subtitle="Is life out there?"
          dark={true}
          glitch={ufo}>
          </Section>
          <Ufo className="nav-logo"/>
          <p className="description">Are we alone? That is the question that we have all asked. We belive that through investigation into areas prone to visitations, or interviews with those who have accounts, we will be able to study this phenomena better. </p>
        </div>
      </div>
    )
}

export default Services
