import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Navbar from './Navigation';
import Footer from './Location';
import exmo from './exmo.svg'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <header className="App-header">
        <div>
        <Navbar/>
        </div>
      </header>
       <Switch>
          <Route path="/services" component={Services}/>
          <Route path="/about" component={About}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/" component={Home}/>
          <Route component={Error}></Route>
        </Switch>
      <footer>
        <Footer className="footer"/>
      </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
