import React from 'react'
import ATVParallax from 'react-atv-parallax';
import jose from './img/Jose.jpg'
import mykayla from './img/Mykayla.jpg'

const About = () => {
    return (
        <div className="home">
            <h1>Our Story</h1>
            <div className="pictures">
            <img src={mykayla} className="portrait"/>
            <img src={jose} className="portrait"/>
            </div>
            <p className="description">Wanting to explore washington for Cryptid creatures? Nevada's Area 51, Maybe the Wright-Patterson Air Force Base for some sky lights? </p>
            <p className="description"> Started out of a passion for the strange, founded for exploring the paranormal where it lies, we aim to not disturb the peace, but apply skeptical, scientific processes for logging events and triggers of what otherwise cannot be explained.</p>
        </div>
    )
}

export default About
