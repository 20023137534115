import React from "react";

export default function Section({ title, subtitle, dark, id }) {
  return (
    <div className={(dark ? " section-dark" : "")}>
      <div className="section-content" id={id}>
        <span style={{paddingTop:'10em'}}/>
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <div class="glitch">
					<div class="glitch__img"></div>
				</div>

      </div>
    </div>
  );
}
